import React from "react";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  Text,
  Wrapper,
  StyledImg,
} from "./styles";
import PageContainer from "../PageContainer";
import Image from "./Images";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const FoodAndHabitsAvoid = () => {
  const image = Image();

  return (
    <>
      <Breadcrumb pageTitle="Foods And Habits to Avoid">
        <BreadcrumbItem
          Link="/patient-information/foods-and-habits-to-avoid"
          Text="Foods And Habits to Avoid"
        />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Title>Which Foods should I avoid to prevent breaking braces?</Title>
          <Text>
            <i>
              Breaking orthodontic appliances are almost always caused by
              patients eating foods they shouldn&#39;t be eating.
            </i>{" "}
            We encourage patients to avoid eating hard foods, sugary foods and
            sticky foods.
          </Text>
          <Text>
            Hard foods can easily break or damage teeth, brackets and wires.
            Sticky foods can easily get trapped in between teeth, wires and
            brackets and sometimes pull wires and braces off teeth!
          </Text>
          <Text>
            Examples of hard foods to avoid include nuts, hard crusty breads,
            popcorn, ice, corn on the cob, chips, raw carrots and apples (cut
            into small pieces or have grated).
          </Text>
          <Text>
            Examples of sticky foods to avoid include chewy lollies, rollups,
            liquorice, toffee, caramels and gum (both sugar-free and regular
            gum).
          </Text>
          <Wrapper>
            <ContentsContainer>
              <Title>Which Foods should I avoid to prevent cavities?</Title>
              <Text>
                Sugary foods should be avoided as they cause dental cavities.
                This will impact your teeth, orthodontic treatment and
                appliances. In some instances, if there is concern about a poor
                diet habit and cavities, a tough decision may have to be made to
                cease orthodontic treatment to prevent long term issues arising.
              </Text>
              <Text>
                During orthodontic treatment it is important to minimise sugary
                foods like lollies, soft drink, sports drinks, juices, cake, ice
                cream and biscuits.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.food} />
            </ImageContainer>
          </Wrapper>
          <Wrapper>
            <ContentsContainer>
              <Title>Which Habits should I avoid?</Title>
              <Text>
                Habits, such as fingernail biting, pencil and pen chewing should
                be discouraged during treatment. These habits increase chances
                of breaking your braces and orthodontic appliances.
              </Text>
              <Text>
                If any damage is to occur to your orthodontic appliance, you
                should notify our team at Gippsland Orthodontics immediately.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.habit} />
            </ImageContainer>
          </Wrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default FoodAndHabitsAvoid;
