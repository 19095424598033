import { useStaticQuery, graphql } from "gatsby";

const Image = () => {
  const data = useStaticQuery(graphql`
    fragment ImagePIPhotos on File {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      creditCards: file(relativePath: { eq: "etc/cards.png" }) {
        ...ImagePIPhotos
      }
      food: file(relativePath: { eq: "etc/bad-food.jpg" }) {
        ...ImagePIPhotos
      }
      habit: file(relativePath: { eq: "etc/chewing.png" }) {
        ...ImagePIPhotos
      }
    }
  `);

  return {
    creditCards: data.creditCards.childImageSharp.fluid,
    food: data.food.childImageSharp.fluid,
    habit: data.habit.childImageSharp.fluid,
  };
};
export default Image;
