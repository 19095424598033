import React from "react";
import Layout from "../../components/layout";
import FoodAndHabitsAvoid from "../../components/patient-information/food-habits";
import SEO from "../../components/seo";

const FoodAndHabitsAvoidPage = () => (
  <Layout>
    <SEO title="Foods And Habits to Avoid" />
    <FoodAndHabitsAvoid />
  </Layout>
);

export default FoodAndHabitsAvoidPage;
